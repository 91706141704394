<template>
  <div class="shopmall-empty fs-16 bg-white py-4 mb-6 w-100">
    <div class="icon">
      <slot>
        <div class="svg-image" v-if="!emptyImg" :style="{ height: `${height}px`, width: `${width}px` }"></div>
        <img v-else :src="emptyImg" :width="`${width}`" />
      </slot>
    </div>
    <div class="text mt-5" name="empty-text">
      <slot name="text">
        <p v-if="emptyText" v-text="emptyText" class="fc-black-06 fs-14"></p>
        <p v-if="emptyTextSub" v-text="emptyTextSub" class="fc-black-06 fs-12"></p>
        <p v-if="!emptyText && !emptyTextSub" class="fc-black-09">
          Your cart is still empty.
          <span class="sp-link ml-2" @click="goShopping">Go shopping!</span>
        </p>
      </slot>
    </div>
  </div>
</template>

<script setup>
// 定义自定义事件去购物
const emit = defineEmits(['go-shopping'])

import emptyDefaultImg from '~/assets/imgs/empty-list.svg'

const goShopping = () => {
  emit('go-shopping')
}

const props = defineProps({
  emptyImg: {
    // 自定义图片
    type: String,
    default: emptyDefaultImg // 默认为空字符串，表示使用默认图标
  },
  emptyText: {
    type: String,
    default: '' // 默认为空字符串，表示使用默认
  },
  emptyTextSub: {
    type: String,
    default: '' // 默认为空字符串，表示使用默认
  },
  height: {
    type: Number,
    default: 384
  },
  width: {
    type: Number,
    default: 385
  }
})
</script>

<style lang="scss" scoped>
.shopmall-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icon {
  margin-bottom: 10px;
}

.svg-image {
  width: 200px;
  height: 200px;
  background-image: var(--empty-img);
  background-size: cover;
}
</style>
